import React, { PureComponent } from 'react';
import Carousel from '../../common/carousel';
import {
	HomeWrapper,
	PageListWrapper,
	PageListItem
} from './style';

class Home extends PureComponent {
	render() {
		return(
			<div>
				<Carousel />
				<HomeWrapper>
					<PageListWrapper>
						<PageListItem />
						<PageListItem />
						<PageListItem />
						<PageListItem />
					</PageListWrapper>
				</HomeWrapper>
			</div>
		)
	}

	componentDidMount() {
		window.scrollTo(0,0)
	}
}

export default Home;