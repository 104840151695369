import styled from 'styled-components';
import { downloadBanner } from '../../statics/imgSrc';

export const HomeWrapper = styled.div`
	position: relative;
	width: 100%;
	margin: 0 auto;
	background: #e9eaeb;
`;

export const BannerWrapper = styled.div`
	width: 100%;
	min-width: 1200px;
	height: 11.78vw;
	min-height: 141px;
	background: url(${downloadBanner}) no-repeat top left;
	background-size: 100%;
`;

export const PageWrapper = styled.div`
	display: block;
	height: auto;
`;


export const DownloadBox = styled.div`
	width: 1200px;
	height: 600px;
	margin: 0 auto;
	padding-top: 70px;
	text-align: center;
`;

export const DownloadItem = styled.div`
	width: 260px;
	height: 450px;
	background: #fff;
	border: 1px solid #cdd0d3;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	position: relative;
	padding-top: 30px;
	margin-left: 30px;
	&:nth-child(1) {
		margin-left: 0;
	}
	.downloadIcon{
		width: 141px;
		height: 118px;
		margin: 0 auto 30px;
		display: block;
	}
	.qrcodeImg{
		width: 100%;
		height: 158px;
		margin: 0 auto 30px;
		img{
			width: auto;
			height: 158px;
		}
	}
	&.windows ,
	&.mac {
		.qrcodeImg{
			img{
				height: 125px;
			}
		}
	}
	.downloadBtn{
		width: 210px;
		height: 46px;
		margin: 0 auto;
		border-radius: 4px;
		background: #469ef0;
		color: #fff;
		font-size: 15px;
		line-height: 46px;
		text-align: center;
		cursor: pointer;
	}
	.downloadTs{
		width: 100%;
		height: 20px;
		line-height: 20px;
		text-align: center;
		font-size: 13px;
		color: #469ef0;
		margin-top: 8px;
		user-select: none;
	}
`;
