export const logo = require('./images/logo.png');
export const bannerList = [
	{
		id: 'applyUse',
		imgUrl: require('./images/carousel2.png')
	}, {
		id: 'download',
		imgUrl: require('./images/carousel1.png')
	}
];
export const homePageList = [
	require('./images/homePageList1.png'),
	require('./images/homePageList2.png'),
	require('./images/homePageList3.png'),
	require('./images/homePageList4.png')
];
export const downloadBanner = require('./images/downloadBanner.png');
export const downloadImgList = [
	{
		id: 'android',
		imgSrc: require('./images/android.png'),
		qrCodeSrc: require('./images/andQrcode.png'),
		btnText: 'Android客户端下载'
	}, {
		id: 'ios',
		imgSrc: require('./images/ios.png'),
		qrCodeSrc: require('./images/iosQrcode.png'),
		btnText: 'IOS客户端下载'
	}, {
		id: 'windows',
		imgSrc: require('./images/windows.png'),
		qrCodeSrc: require('./images/winQrcode.png'),
		btnText: 'Windows客户端下载'
	}, {
		id: 'mac',
		imgSrc: require('./images/mac.png'),
		qrCodeSrc: require('./images/macQrcode.png'),
		btnText: 'Mac客户端下载'
	}
];
export const applyUseBtn = require('./images/applyUseBtn.png');
export const downloadBtn = require('./images/downloadBtn.png');
export const Bitmap = require('./images/bitmap.png');