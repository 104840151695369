import { combineReducers } from 'redux-immutable';
import { reducer as headerReducer } from '../common/header/store';
import { reducer as carouselReducer } from '../common/carousel/store';
import { reducer as downloadReducer } from '../pages/download/store';
import { reducer as applyUseReducer } from '../pages/applyUse/store';

const reducer = combineReducers({
	header: headerReducer,
	carousel: carouselReducer,
	download: downloadReducer,
	applyUse: applyUseReducer
})

export default reducer;