import { fromJS } from 'immutable';
import { bannerList } from '../../../statics/imgSrc';

let carouselList = bannerList.map((item,index) => {
	return {
		id: item.id,
		index: (2 - index),
		imgUrl: item.imgUrl
	}
})

const defaultState = fromJS({
	carouselList
})

export default (state = defaultState, action) => {
	switch(action.type) {
		default :
			return state
	}
}