import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
	sendPhoneCode: false,
	requestRes: ''
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case constants.CHANGE_PHONECODE:
			return state.set('sendPhoneCode', action.value);
		case constants.CHANGE_REQUESTRES: 
			return state.set('requestRes', action.value);
		default :
			return state;
	}
}