import styled from 'styled-components';
import { logo } from '../../statics/imgSrc';

export const HeaderWrapper = styled.div`
	position: relative;
	height: 90px;
	background: #fff;
`;

export const HeaderBox = styled.div`
	position: relative;
	width: 1200px;
	height: 100%;
	margin: 0 auto;
	padding: 0;
`;

export const Logo = styled.a.attrs({
	href: '/'
})`
	float: left;
	display: block;
	width: 190px;
	height: 90px;
	background: url(${logo});
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
`;

export const Nav = styled.div`
	width: 288px;
	height: 100%;
	padding: 36px 0;
	float: right;
`;

export const NavItem = styled.div`
	width: 96px;
	height: 100%;
	line-height: 18px;
	text-align: center;
	font-size: 15px;
	float: left;
	color: #333;
	cursor: pointer;
	span{
		width: auto;
		display: inline-block;
		height: 100%;
	}
	&.active {
		span{
			color: #1e92fc;
		}
	}
	&:hover {
		span{
			color: #1e92fc;
		}
	}
`;
