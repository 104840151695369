import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	HomeWrapper,
	BannerWrapper,
	PageWrapper,
	DownloadBox,
	DownloadItem
} from './style';

class Download extends PureComponent {
	render() {
		const { downloadList } = this.props;
		return(
			<HomeWrapper>
				<BannerWrapper />
				<PageWrapper>
					<DownloadBox>
						{
							downloadList.map((item, index) => {
								return <DownloadItem
											key={index}
											className={item.get('id')}
										>
											<img className="downloadIcon" src={item.get('imgSrc')} alt="" />
											<div className="qrcodeImg"><img src={item.get('qrCodeSrc')} alt="" /></div>
											<div className="downloadBtn" onClick={() => {this.downloadApp(item.get('id'))}}>{item.get('btnText')}</div>
											{
												(item.get('id') === 'android')?<p className="downloadTs">支持浏览器扫码或QQ扫码</p>:null
											}
										</DownloadItem>
							})
						}
					</DownloadBox>
				</PageWrapper>
			</HomeWrapper>
		)
	}

	componentDidMount() {
		window.scrollTo(0,0)
	}
	
	downloadApp = (id) => {
		const { downloadSrc } = this.props;
		console.log(id)
		let downloadUrl = downloadSrc.get(id)
		console.log(downloadUrl)
		if(downloadUrl) {
			// window.location.href = downloadUrl
			window.open(downloadUrl)
		}
	}
}

const mapState = (state) => ({
	downloadList: state.getIn(['download','downloadList']),
	downloadSrc: state.getIn(['download','downloadSrc'])
});

export default connect(mapState, null)(Download);