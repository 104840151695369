import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { 
	HeaderWrapper, 
	HeaderBox, 
	Logo, 
	Nav, 
	NavItem
} from './style';

class Header extends Component {

	render() {
		const { navList } = this.props;
		return (
			<HeaderWrapper>
				<HeaderBox>
					<Logo />
					<Nav>
						{
							navList.map(item => {
								return (
									<Link 
										key={item.get('id')}
										to={item.get('href')}
									>
										<NavItem 
											className={
												(this.props.location.pathname.indexOf('csKnowledeg')>=0 || this.props.location.pathname.indexOf('employment')>=0) ? 
												((('/' + this.props.location.pathname.split('/')[1]) === item.get('href'))?'active':'') :
												((this.props.location.pathname === item.get('href'))?'active':'')
											}
										>
											<span>{item.get('name')}</span>
										</NavItem>
									</Link>
								)
							})
						}
					</Nav>
				</HeaderBox>
			</HeaderWrapper>
		)
	}
} 

const mapState = (state) => ({
	navList: state.getIn(['header','navList'])
});


export default connect(mapState, null)(withRouter(Header));
