import styled from 'styled-components';

export const HomeWrapper = styled.div`
	position: relative;
	width: 100%;
	margin: 0 auto;
	background: #e9eaeb;
	padding: 30px 0 20px;
`;

export const PageWrapper = styled.div`
	display: block;
	width: 800px;
	height: auto;
	background: #fff;
	border-radius: 4px;
	padding: 50px 50px 90px;
	margin: 0 auto;
`;

export const Title = styled.h3`
	width: 100%;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 19px;
	color: #303133;
	font-weight: bold;
`;

export const Intro = styled.p`
	width: 100%;
	height: 24px;
	line-height: 24px;
	text-align: center;
	font-size: 13px;
	color: #909399;
`;

export const FromBox = styled.div`
	width: 525px;
	height: auto;
	margin: 45px auto 24px;
`;

export const FromItem = styled.div`
	width: 100%;
	height: 72px;
	position: relative;
	padding-left: 100px;
	.label {
		width: 100px;
		height: 36px;
		position: absolute;
		top: 0;
		left: 0;
		line-height: 36px;
		text-align: left;
		font-size: 15px;
		color: #303133;
		font-weight: bold;
	}
	.contentDiv{
		width: 100%;
		height: 36px;
		position: relative;
		z-index: 20;
		&.imgcodeDiv {
			padding-right: 98px;
		}
		&.codeDiv {
			padding-right: 113px;
		}
		.inputDiv {
			width: 100%;
			height: 36px;
			border: 1px solid #ececec;
			border-radius: 4px;
			overflow: hidden;
			&.hasError{
				border-color: rgba(255,0,0,.5);
			}
			input {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
				padding: 0 12px;
				font-size: 14px;
				color: #303133;
			}
			input::-webkit-input-placeholder {
				color: #909399;
			}
		}
		.imgcodeArea{
			width: 86px;
			height: 36px;
			position: absolute;
			right: 0;
			top: 0;
			border-radius: 4px;
			cursor: pointer;
			img{
				width: 100%;
				height: 100%;
				display: block;
			}
			.imgLoading {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				font-size: 26px;
				background: rgba(255,255,255,.2);
				padding-top: 5px;
			}
		}
		.codeArea{
			width: 100px;
			height: 36px;
			position: absolute;
			right: 0;
			top: 0;
			border: 1px solid #d8d9da;
			border-radius: 4px;
			line-height: 36px;
			text-align: center;
			font-size: 11px;
			color: #909399;
			background: #f4f4f5;
			cursor: pointer;
			.codeLoading {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				font-size: 26px;
				background: rgba(255,255,255,.2);
				padding-top: 5px;
			}
		}
	}
	.inputErr{
		width: 100%;
		height: 36px;
		line-height: 30px;
		text-align: left;
		font-size: 13px;
		color: rgba(255,0,0,.8);
		padding-left: 112px;
		position: absolute;
		top: 0;
		left: 0;
		transition: top ease-out .5s;
		-webkit-transition: top ease-out .5s;
		&.show{
			top: 36px;
		}
	}
`;

export const SubmitBtn = styled.div`
	width: 140px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	font-size: 15px;
	color: #fff;
	background: #469ef0;
	border-radius: 4px;
	margin: 0 auto;
	cursor: pointer;
	position: relative;
	.applyLoading {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		font-size: 26px;
		background: rgba(255,255,255,.2);
		padding-top: 5px;
	}
`;