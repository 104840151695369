import React, { PureComponent } from 'react';
import {
	FooterWrapper,
	FooterContent,
	ContentWrapper,
	ContentItem,
	CopyrightBox,
	CopyrightItem
} from './style';

class Footer extends PureComponent {
	render () {
		return (
			<FooterWrapper>
				<FooterContent>
					<ContentWrapper>
						<ContentItem>
							<h4>联系我们</h4>
							<p>商户合作电话：010-21722110</p>
							<p>公司地址：北京市丰台区丰科路6号院2号楼15层1512</p>
						</ContentItem>
						<ContentItem>
							<h4>工作时间</h4>
							<p>周一至周五 09:30~18:30</p>
						</ContentItem>
					</ContentWrapper>
				</FooterContent>
				<CopyrightBox>
					{/* <CopyrightItem className="left">山东企智通企业服务有限公司 版权所有</CopyrightItem> */}
					{/* <CopyrightItem className="right">Copyright 2022 企智通企业服务. <a target="_blank" href="http://www.beian.miit.gov.cn">鲁ICP备19030182号-1</a>. All Rights Reserved.</CopyrightItem> */}
          {/* <CopyrightItem className="left">徐州创薪时代网络科技有限公司 版权所有</CopyrightItem>
					<CopyrightItem className="right">Copyright 2022 企智云企业服务. <a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备20042751号-1</a>. All Rights Reserved.</CopyrightItem> */}
					<CopyrightItem className="left">北京企智云科技有限公司 版权所有</CopyrightItem>
					<CopyrightItem className="right">Copyright 2022 企智云企业服务. <a target="_blank" href="https://beian.miit.gov.cn/">京ICP备20010457号-1</a>. All Rights Reserved.</CopyrightItem>
				</CopyrightBox>
			</FooterWrapper>
		)
	}
}

export default Footer;