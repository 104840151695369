import styled from 'styled-components';
import { homePageList } from '../../statics/imgSrc';

export const HomeWrapper = styled.div`
	position: relative;
	width: 100%;
	margin: 0 auto;
`;

export const PageListWrapper = styled.div`
	display: block;
	height: auto;
`;

export const PageListItem = styled.div`
	width: 100%;
	min-width: 1200px;
	height: 400px;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 1200px 400px;
	&:nth-child(1) {
		background-image: url(${homePageList[0]});
    background-color: #fff;
	}
	&:nth-child(2) {
		background-image: url(${homePageList[1]});
		background-color: #f5f8fa;
	}
	&:nth-child(3) {
		background-image: url(${homePageList[2]});
    background-color: #fff;
	}
	&:nth-child(4) {
		background-image: url(${homePageList[3]});
		background-color: #f5f8fa;
	}
`;