import React, { Component } from 'react';
import { GlobalStyle } from './style.js';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Route } from 'react-router-dom';
import Header from './common/header';
import Home from './pages/home';
import ApplyUse from './pages/applyUse';
import Download from './pages/download';
import Footer from './common/footer';
import { PageWrapper, BodyWrapper, BrowserWrapper } from './style';

class App extends Component {

	render() {
		return (
		    <BodyWrapper>
			 	<GlobalStyle />
 				<BrowserWrapper>
					<PageWrapper>
			 			<Header />
						<Route path="/" exact component={Home}></Route>
						<Route path="/download" exact component={Download}></Route>
						<Route path="/applyUse" exact component={ApplyUse}></Route>
		 			</PageWrapper>
		 			<Footer />
				</BrowserWrapper>
		    </BodyWrapper>
	  	)
	}
}

export default App;
