import styled from 'styled-components';

export const FooterWrapper = styled.div`
	width: 100%;
	min-width: 1200px;
	height: 356px;
	background: #000;
	position: relative;
	margin-top: -356px;
`;

export const FooterContent = styled.div`
	height: 312px;
	background: #333;
	padding: 60px 0;
`;

export const ContentWrapper = styled.div`
	width: 1140px;
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
`;

export const ContentItem = styled.div`
	width: 70%;
	float: left;
	padding: 0 15px;
	color: #c6c6c6;
	line-height: 1.2;
	&:nth-child(2) {
		width: 30%;
	}
	h4 {
		font-size: 22px;
		margin-bottom: 40px;
		color: #fff;
	} 
	p {
		font-size: 18px;
		margin-bottom: 30px;
	}
`;
export const CopyrightBox = styled.div`
	width: 1140px;
	height: 44px;
	padding: 10px 0;
	margin: 0 auto;
	overflow: hidden;
`;

export const CopyrightItem = styled.div`
	width: 50%;
	color: #777;
	line-height: 24px;
	font-size: 15px;
	&.left {
		float: left;
		text-align: left;
	}
	&.right {
		float: right;
		text-align: right;
	}
	a {
		text-decoration: none;
		color: #aaa;
	}
`;