import { fromJS } from 'immutable';
const defaultState = fromJS({
	navList: [{
		id: 1,
		name: '产品介绍',
		href: '/'
	}, {
		id: 2,
		name: '下载中心',
		href: '/download'
	}, {
		id: 3,
		name: '申请试用',
		href: '/applyUse'
	}]
})

export default (state = defaultState, action) => {
	switch(action.type) {
		default :
			return state
	}
}