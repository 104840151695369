import axios from 'axios';
import { message } from 'antd';

// const baseURL = 'http://tagent.51xinpai.cn:1024';

const service = axios.create({
	// baseURL: baseURL,
    timeout: 30001,
    withCredentials: false,
	changeOrigin: true
});

//请求拦截
service.interceptors.request.use(config => {
    if(!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json;charset=utf-8'
    return config
}, error => {
    Promise.reject(error)
})

//响应拦截
service.interceptors.response.use(response => {
  if (response.status === 200) {
	  console.log(response.config.url)
    let data = response.data;
    data.url = response.config.url.split('/').slice(-1).join('');
    return data
  }
}, error => {
    if (error && error.response) {
      if (error.response.status === 403) {
        message.warning('无访问权限！');
      }
	} else {
    if(error.config) {
        if(error.message.indexOf('timeout') >= 0) {
          message.error({
            content: '网络连接超时，请稍后重试！',
            key: 'timeout',
            duration: 2
          });
        } else {
          message.error({
            content: '网络异常，请刷新后重试！',
            key: 'refresh',
            duration: 2
          });
        }
    } else {
      message.error({
        content: '您的浏览器不受支持，我们建议您使用最新版本的谷歌浏览器或者Firefox浏览器。',
        key: 'noSupport',
        duration: 2
      });
    }
	}
  return Promise.reject(error)
})

const postRequest = (url, params = {}) => {
  return service({
    method: 'post',
    url: '/qzymeeting/apply/' + url,
    data: params
  })
}
const getRequest = (url, params = {}) => {
  return service({
    url: '/qzymeeting/apply/' + url,
    params: params
  })
}

const Axios = {
    post: postRequest,
    get: getRequest
}

export default Axios
