import { fromJS } from 'immutable';
import { downloadImgList } from '../../../statics/imgSrc';

let downloadList = downloadImgList.map((item,index) => {
	return {
		id: item.id,
		imgSrc: item.imgSrc,
		qrCodeSrc: item.qrCodeSrc,
		btnText: item.btnText
	}
})

const defaultState = fromJS({
	downloadList,
	downloadSrc: {
		mac: 'https://qzym.oss-cn-beijing.aliyuncs.com/download/qzy.zip',
		windows: 'https://qzym.oss-cn-beijing.aliyuncs.com/download/qzy.exe',
		android: 'https://qzym.oss-cn-beijing.aliyuncs.com/download/qzy.apk',
		ios: 'https://apps.apple.com/cn/app/%E4%BC%81%E6%99%BA%E4%BA%91%E8%A7%86%E9%A2%91/id1501282346'
	}
})

export default (state = defaultState, action) => {
	switch(action.type) {
		default :
			return state
	}
}