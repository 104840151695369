import * as constants from './actionTypes';
import { message } from 'antd';
import { getImgCode, getPhoneCode, applyUser } from '../../../assets/api/interface';

const sendPhonenoCode = (value) => ({
	type: constants.CHANGE_PHONECODE,
	value
});

const requestRes = (value) => ({
	type: constants.CHANGE_REQUESTRES,
	value
});

export const toApplyUser = ({username,companyname,phone,code}) => {
	return (dispatch) => {
		applyUser(username,companyname,phone,code).then(res => {
			dispatch(requestRes(res));
		}).catch((e) => {
			message.error('申请失败，请稍后重试');
		});
	}
};

export const getImageCode = () => {
	return (dispatch) => {
		getImgCode().then((res) => {
			dispatch(requestRes(res));
		}).catch(e => {
			message.error('请求失败，请点击重试');
		})
	}
};

export const getPhonenoCode = ({phone, imgcode, imgkey}) => {
	return (dispatch) => {
		getPhoneCode(phone, imgcode, imgkey).then(res => {
			dispatch(requestRes(res));
			if(res.c === 0) {
				dispatch(sendPhonenoCode(true));
				setTimeout(() => {
					dispatch(sendPhonenoCode(false));
				},1000);
			}
		}).catch(e => {
			message.error('请求失败，请点击重试');
		})
	}
}

export const resetRequestRes = () => {
	return(dispatch) => {
		dispatch(requestRes(''));
	}
};