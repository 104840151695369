import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	CarouselWrapper,
	CarouselBox,
	CarouselItem,
	PaginationBox
} from './style';
let Swiper = window.Swiper;
let mySwiper ;

class Carousel extends PureComponent{
	componentWillUnmount() {
		if (mySwiper) { // 销毁swiper
		 	mySwiper.destroy()
		 	mySwiper = null;
		}
	}

	componentDidMount() {
		if(mySwiper){
			try{
		 		mySwiper.destroy()
			}catch(e){}
		 	mySwiper = null;
		}
		setTimeout(() => {
			mySwiper = new Swiper(this.refs.carousel, {
		        loop: true,
		        pagination: {
			 		el: this.refs.pagination
			 	},
		        autoplay: true
		 	});
		},500)
	}

	render(){
		const { list, navList } = this.props
		return(
		 	<CarouselWrapper className="swiper-container" ref="carousel">
			 	<CarouselBox className="swiper-wrapper">
			 		{
			 			list.map((item) => {
			 				return (
								<CarouselItem className="swiper-slide" key={item.get('id')}>
									<img src={item.get('imgUrl')} alt=""/>
									<Link to={navList.getIn([item.get('index'),'href'])}>
										<div className={item.get('id') + ' turnToBtn'}></div>
									</Link>
								</CarouselItem>
			 				)
			 			})
			 		}
				</CarouselBox>
				<PaginationBox ref="pagination"></PaginationBox>
			</CarouselWrapper>
		)
	}
}

const mapState = (state) => ({
	list: state.getIn(['carousel','carouselList']),
	navList: state.getIn(['header','navList'])
});

export default connect(mapState, null)(Carousel);