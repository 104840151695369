import request from './request';
import * as requestTypes from './requestTypes';

/** 获取图片验证码
 * @returns {AxiosPromise}
 */
export function getImgCode() {
    return request.get(requestTypes.GETIMGCODE)
}


/** 获取手机验证码
 * @param phoneno
 * @param verificationCode
 * @param verificationKey
 * @returns {AxiosPromise}
 */
export function getPhoneCode(phoneno,verificationCode,verificationKey) {
    return request.get(requestTypes.GETPHONECODE,{
        'parameter': {
			'phoneno':phoneno,
			'verificationCode':verificationCode,
			'verificationKey':verificationKey
		}
    })
}

/** 注册
 * @param companyContacts
 * @param companyName
 * @param phone
 * @param phoneCode
 * @returns {AxiosPromise}
 */
export function applyUser(companyContacts,companyName,phone,phoneCode) {
    return request.get(requestTypes.APPLYUSE,{
		'parameter' : {
			'companyContacts':companyContacts,
			'companyName':companyName,
			'phone':phone,
			'phoneCode':phoneCode
		}
    })
}
