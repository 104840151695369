import styled from 'styled-components';
import { downloadBtn, applyUseBtn } from '../../statics/imgSrc';

export const CarouselWrapper = styled.div`
	position: relative;
	width: 100%;
	min-width: 1200px;
	height: 26.25vw;
	min-height: 315px;
`;

export const CarouselBox = styled.div`
	width: 100%;
	height: 100%;
`;

export const CarouselItem = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	img {
		width: 100%;
	}
	.turnToBtn {
		position: absolute;
		bottom: 15%;
		left: 19%;
		width: 9.44vw;
		min-width: 113px;
		height: 2.81vw;
		min-height: 33.5px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;
		cursor: pointer;
		&.applyUse {
			background-image: url(${applyUseBtn})
		}
		&.download {
			background-image: url(${downloadBtn})
		}
	}
`;

export const PaginationBox = styled.div`
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	z-index: 10;
	span {
		width: 70px;
		height: 4px;
		position: relative;
		-webkit-box-flex: 0;
	    -ms-flex: 0 1 auto;
	    flex: 0 1 auto;
	    margin-right: 3px;
	    margin-left: 3px;
	    text-indent: -999px;
	    background-color: rgba(255,255,255,.5);
	    border-radius: 0;
	    &.swiper-pagination-bullet-active{
	    	background-color: #fff;
	    }
	}
`;
