import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	HomeWrapper,
	PageWrapper,
	Title,
	Intro,
	FromBox,
	FromItem,
	SubmitBtn
} from './style';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { actionCreators } from './store';
import * as requestTypes from '../../assets/api/requestTypes';
import { Bitmap } from '../../statics/imgSrc';

class ApplyUse extends PureComponent {
	
	constructor(props) {
	    super(props)
		this.state = {
			applyForm: [{
				id: 'username',
				text: '姓名',
				placeholder: '请输入您的姓名',
				maxlength: 12,
				value: '',
				error: ''
			}, {
				id: 'companyname',
				text: '企业全称',
				placeholder: '请输入您的企业全称',
				maxlength: 20,
				value: '',
				error: ''
			}, {
				id: 'phone',
				text: '联系电话',
				placeholder: '请输入您的联系电话',
				maxlength: 11,
				value: '',
				error: ''
			}, {
				id: 'imgcode',
				text: '图形验证码',
				classname: 'imgcodeDiv',
				placeholder: '请输入右侧图形上的数字',
				maxlength: 3,
				value: '',
				error: ''
			}, {
				id: 'code',
				text: '短信验证码',
				classname: 'codeDiv',
				placeholder: '请输入短信验证码',
				maxlength: 6,
				value: '',
				error: ''
			}],
			imgLoading: false,
			imgCodeKey: '',
			imgCodeUrl: Bitmap,
			codeLoading: false,
			phoneCodeText: '获取验证码',
			timeDown: null,
			applyLoading: false,
			applyText: '立即申请'
		}
		this.changeInputValue = this.changeInputValue.bind(this)
		this.submitApply = this.submitApply.bind(this)
	}
	
	render() {
		return(
			<HomeWrapper>
				<PageWrapper>
					<Title>企智云视频-欢迎免费试用</Title>
					<Intro>现在申请,我们的一对一客户经理,会尽快和您联系,帮您开通试用账号</Intro>
					<FromBox>
						{
							this.state.applyForm.map((item, index) => {
								return <FromItem
											key={item.id}
											>
											<div className="label">{item.text}</div>
											<div className={item.classname?(item.classname + " contentDiv"):"contentDiv"}>
												<div className={item.error?"inputDiv hasError":"inputDiv"}>
													<input 
														value={item.value} 
														onChange={(e) => {this.changeInputValue(index, e)}} 
														placeholder={item.placeholder} 
														maxLength={item.maxlength}
													/>
												</div>
												{
													item.classname?(
														(item.classname === 'imgcodeDiv')?(
															<div className="imgcodeArea">
																<img src={this.state.imgCodeUrl} alt="" onClick={this.getImgCode} />
																{
																	this.state.imgLoading?<LoadingOutlined className="imgLoading" />:''
																}
															</div>
														):(
															<div className="codeArea" onClick={this.getPhoneCode}>
																{this.state.phoneCodeText}
																{
																	this.state.codeLoading?<LoadingOutlined className="codeLoading" />:''
																}
															</div>
														)
													):null
												}
											</div>
											<div className={item.error?"inputErr show":"inputErr"}>{item.error}</div>
										</FromItem>
							})
						}
					</FromBox>
					<SubmitBtn onClick={this.submitApply}>
						{this.state.applyText}
						{
							this.state.applyLoading?<LoadingOutlined className="applyLoading" />:''
						}
						</SubmitBtn>
				</PageWrapper>
			</HomeWrapper>
		)
	}
	
	componentWillReceiveProps(nextProps) {
		const { requestRes, resetRequestRes } = nextProps;
		console.log(requestRes);
		if(requestRes) {
			let res = requestRes;
			if(res.c !== 0) {
				message.error(res.m);
			}
			switch(res.url) {
				case requestTypes.GETIMGCODE: 
					if(res.c === 0) {
						this.setState(() => ({
							imgCodeUrl: 'data:image/png;base64,' + res.d.img,
							imgCodeKey: res.d.key
						}));
					}
					this.resetImgLoading();
					break;
				case requestTypes.GETPHONECODE: 
					if(res.c === 0) {
						this.timeDown();
					}
					this.setState({ codeLoading: false });
					break;
				case requestTypes.APPLYUSE: 
					this.setState({ applyLoading: false });
					if(res.c === 0) {
						this.applySuccess();
					}
					break;
				default:
					break;
			}
			resetRequestRes();
		}
	}

	componentDidMount() {
		window.scrollTo(0,0)
		this.getImgCode()
	}
	
	componentWillUnmount() {
		clearInterval(this.state.timeDown);
	}
	
	handleSubmit = (e) => {
		e.preventDefault()
	}
	
	changeInputValue = (index,e) => {
		console.log(index, e.target.value)
		let applyForm = this.state.applyForm
		applyForm[index].value = e.target.value
		applyForm[index].error= ''
		this.setState(() => ({
			applyForm: [...applyForm]
		}));
		console.log(this.state)
	}
	
	getImgCode = () => {
		const { getImgCodes } = this.props;
		this.setState({ imgLoading: true });
		getImgCodes();
	}
	
	resetImgLoading = () => {
		this.setState({ imgLoading: false });
	}
	
	changeImgCode = (e) => {
		const value = e.target.value;
		this.setState(() => ({
			imgCode: value
		}))
	}
	
	checkFrom = (ignore) => {
		if(!ignore) ignore = ''
		let applyForm = this.state.applyForm;
		let phoneReg = /^1[3456789]\d{9}$/;
		let checkRes = {
			flag: true,
			data: {
				imgkey: this.state.imgCodeKey
			}
		};
		applyForm.forEach(item => {
			if(ignore.indexOf((',' + item.id)) >= 0) {
				return
			}
			let val = item.value.replace(/\s/g,'');
			if(!val) {
				item.error = item.placeholder
				checkRes.flag = false
			} else {
				checkRes.data[item.id] = item.value
			}
			if(item.id === 'phone') {
				if(!phoneReg.test(val)) {
					item.error = '请输入格式正确的手机号'
					checkRes.flag = false
				}
			}
		})
		this.setState(() => ({
			applyForm: [...applyForm]
		}));
		return checkRes
	}
	
	getPhoneCode = () => {
		if(this.state.timeDown) {
			return
		}
		let ignore = ',code'
		let checkRes = this.checkFrom(ignore)
		if(!checkRes.flag) {
			return
		}
		const { getPhoneCodes } = this.props;
		this.setState({ codeLoading: true });
		getPhoneCodes(checkRes.data);
	}
	
	timeDown = () => {
		clearInterval(this.state.timeDown);
		let num = 60 ;
		let text = num +  's后重新发送';
		this.setTimeDown(text);
		this.setState(() => ({
			timeDown: setInterval(() => {
				num--;
				text = num +  's后重新发送';
				this.setTimeDown(text);
				if(num <= 0) {
					text = '获取验证码';
					this.setTimeDown(text);
					clearInterval(this.state.timeDown);
					this.setState({ timeDown: null });
				}
			},1001)
		}));
	}

	setTimeDown = (value) => {
		this.setState(() => ({
			phoneCodeText: value
		}));
	}
	
	submitApply = () => {
		if(this.state.applyLoading) {
			return
		}
		let checkRes = this.checkFrom()
		if(!checkRes.flag) {
			return
		}
		const { toApplyUse } = this.props;
		this.setState({ applyLoading: true });
		toApplyUse(checkRes.data);
	}
	
	applySuccess = () => {
		message.success('申请成功');
		this.setState(() => ({
			applyText: '申请成功'
		}));
		setTimeout(() => {
			this.resetApplyForm()
		},1000);
	}
	
	resetApplyForm = () => {
		let applyForm = this.state.applyForm
		applyForm.forEach(item => {
			item.value = ''
			item.error = ''
		})
		this.setState(() => ({
			applyForm: [...applyForm],
			applyText: '立即申请'
		}));
		this.getImgCode()
	}
}

const mapState = (state) => ({
	requestRes: state.getIn(['applyUse','requestRes'])
});

const mapDispatch = (dispatch) => ({
	getImgCodes() {
		dispatch(actionCreators.getImageCode());
	},
	getPhoneCodes(phoneno, code, key) {
		dispatch(actionCreators.getPhonenoCode(phoneno, code, key));
	},
	toApplyUse(applyJson) {
		dispatch(actionCreators.toApplyUser(applyJson))
	},
	resetRequestRes() {
		dispatch(actionCreators.resetRequestRes());
	}
});

export default connect(mapState, mapDispatch)(ApplyUse);